/* @font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-ExtraLight-webfont.eot');
	src: url('../fonts/SourceSansPro-ExtraLight-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-ExtraLight-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-ExtraLight-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-ExtraLight-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-ExtraLightIt-webfont.eot');
	src: url('../fonts/SourceSansPro-ExtraLightIt-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-ExtraLightIt-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-ExtraLightIt-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-ExtraLightIt-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-Light-webfont.eot');
	src: url('../fonts/SourceSansPro-Light-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-Light-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-Light-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-Light-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-LightIt-webfont.eot');
	src: url('../fonts/SourceSansPro-LightIt-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-LightIt-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-LightIt-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-LightIt-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 300;
	font-style: italic;
}
*/

@font-face {
    font-family: 'Source Sans Pro Web';
    src: url('../fonts/SourceSansPro-Regular-webfont.eot');
    src: url('../fonts/SourceSansPro-Regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro-Regular-webfont.woff') format('woff'),
    url('../fonts/SourceSansPro-Regular-webfont.ttf') format('truetype'),
    url('../fonts/SourceSansPro-Regular-webfont.svg#Source Sans Pro') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Web';
    src: url('../fonts/SourceSansPro-It-webfont.eot');
    src: url('../fonts/SourceSansPro-It-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro-It-webfont.woff') format('woff'),
    url('../fonts/SourceSansPro-It-webfont.ttf') format('truetype'),
    url('../fonts/SourceSansPro-It-webfont.svg#Source Sans Pro') format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro Web';
    src: url('../fonts/SourceSansPro-Semibold-webfont.eot');
    src: url('../fonts/SourceSansPro-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro-Semibold-webfont.woff') format('woff'),
    url('../fonts/SourceSansPro-Semibold-webfont.ttf') format('truetype'),
    url('../fonts/SourceSansPro-Semibold-webfont.svg#Source Sans Pro') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Web';
    src: url('../fonts/SourceSansPro-SemiboldIt-webfont.eot');
    src: url('../fonts/SourceSansPro-SemiboldIt-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro-SemiboldIt-webfont.woff') format('woff'),
    url('../fonts/SourceSansPro-SemiboldIt-webfont.ttf') format('truetype'),
    url('../fonts/SourceSansPro-SemiboldIt-webfont.svg#Source Sans Pro') format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro Web';
    src: url('../fonts/SourceSansPro-Bold-webfont.eot');
    src: url('../fonts/SourceSansPro-Bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro-Bold-webfont.woff') format('woff'),
    url('../fonts/SourceSansPro-Bold-webfont.ttf') format('truetype'),
    url('../fonts/SourceSansPro-Bold-webfont.svg#Source Sans Pro') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Source Sans Pro Web';
    src: url('../fonts/SourceSansPro-BoldIt-webfont.eot');
    src: url('../fonts/SourceSansPro-BoldIt-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro-BoldIt-webfont.woff') format('woff'),
    url('../fonts/SourceSansPro-BoldIt-webfont.ttf') format('truetype'),
    url('../fonts/SourceSansPro-BoldIt-webfont.svg#Source Sans Pro') format('svg');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Source Sans Pro Web';
    src: url('../fonts/SourceSansPro-Black-webfont.eot');
    src: url('../fonts/SourceSansPro-Black-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/SourceSansPro-Black-webfont.woff') format('woff'),
    url('../fonts/SourceSansPro-Black-webfont.ttf') format('truetype'),
    url('../fonts/SourceSansPro-Black-webfont.svg#Source Sans Pro') format('svg');
    font-weight: 900;
    font-style: normal;
}

/*
@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-BlackIt-webfont.eot');
	src: url('../fonts/SourceSansPro-BlackIt-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-BlackIt-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-BlackIt-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-BlackIt-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 900;
	font-style: italic;
}
*/

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L005-webfont.eot');
    src: url('../fonts/TitilliumText25L005-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TitilliumText25L005-webfont.woff') format('woff'),
    url('../fonts/TitilliumText25L005-webfont.ttf') format('truetype'),
    url('../fonts/TitilliumText25L005-webfont.svg#Titillium Text') format('svg');
    font-weight: 100;
    font-style: normal;
}

/*
@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L004-webfont.eot');
    src: url('../fonts/TitilliumText25L004-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L004-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L004-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L004-webfont.svg#Titillium Text') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L003-webfont.eot');
    src: url('../fonts/TitilliumText25L003-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L003-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L003-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L003-webfont.svg#Titillium Text') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L002-webfont.eot');
    src: url('../fonts/TitilliumText25L002-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L002-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L002-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L002-webfont.svg#Titillium Text') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L001-webfont.eot');
    src: url('../fonts/TitilliumText25L001-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L001-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L001-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L001-webfont.svg#Titillium Text') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L-webfont.eot');
    src: url('../fonts/TitilliumText25L-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L-webfont.svg#Titillium Text') format('svg');
    font-weight: 900;
    font-style: normal;
} */