@import "fonts.less";
@import "reset.less";

::-moz-selection {
    color: #f1fbff;
    text-shadow: none;
    background: #627a85;
}

::selection {
    color: #f1fbff;
    text-shadow: none;
    background: rgba(98, 122, 133, 0.99); /* for some reason webkit tries to force a semi-transparent background, using an alpha value of 0.99 fixes that */
}

::-webkit-input-placeholder {
    color: rgba(255, 255, 255, .4);
    font-size: 14px;
    font-style: italic;
}

:-moz-placeholder {
    color: rgba(255, 255, 255, .75);
    font-size: 14px;
    font-style: italic;
}

::-moz-placeholder {
    color: rgba(255, 255, 255, .75);
    font-size: 14px;
    font-style: italic;
}

:-ms-input-placeholder {
    color: rgba(255, 255, 255, .75);
    font-size: 14px;
    font-style: italic;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
    -border-radius: 8px;
}

::-webkit-scrollbar-track {
    background-color: #000;
}

::-webkit-scrollbar-thumb {
    background-color: #999;
}

::-webkit-scrollbar-corner {
    display: none;
}

* {
    box-sizing: border-box;
}

html,
button,
input,
select,
textarea {
    font-family: 'Source Sans Pro Web', sans-serif;
    font-size: 14px;
    letter-spacing: -.6px;
}

html {
    height: 100%;
    font-size: 15px;
    overflow-y: scroll;
}

html, body {
    min-height: 100%;
    background-color: #25252d;
}

body {
    display: flex;
    flex-direction: column;
    cursor: default;
    color: #6f6f6f;
    background: #25252d url('../images/background-logo-top.jpg') no-repeat top center;
    background-size: 1200px 1080px;
}

.flexboxtweener,
.flexboxtweener > body {
    height: 100%;
}

html.error {
    body {
        background-image: url('../images/background-logo-top-error.jpg');
    }
}

#no-js-message,
#outdated-message {
    background-color: #bb5f5f;
    color: #fff;
    font-size: 1.5em;
    font-weight: bold;
    padding: 1.5em 0;
    position: fixed;
    top: 0;
    text-align: center;
    width: 100%;
    z-index: 9999;

    a {
        color: #ffe203;
    }
}

#outdated-message,
#outdated-message .ie {
    display: none;
}

.modal {
    position: fixed;
    z-index: 100;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-color: #25252d;
}

h1 {
    display: none;
}

#main-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-bottom: 298px;
    height: 34px;
    color: #fff;
    font-weight: 600;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    background: transparent url('../images/main-menu-background.png') no-repeat top center;

    .list {
        height: 33px;
        overflow: hidden;

        .item {
            position: relative;
            z-index: 1;
            display: inline-block;
            margin-left: 28px;
            line-height: 38px;
            text-shadow: 0 1px 0 rgba(0, 0, 0, .5);

            &:first-child {
                margin: 0;
            }

            a {
                color: #747474;
                text-decoration: none;
                transition: color 200ms;
            }

            a:hover,
            &.selected a {
                color: #d0d0d0;
            }

            a:focus {
                outline: none;
            }

            &:after {
                display: block;
                position: absolute;
                z-index: -1;
                left: 50%;
                bottom: 0;
                margin-left: -20px;
                width: 40px;
                height: 4px;
                content: '';
                opacity: 0;
                border-radius: 40px / 4px;
                box-shadow: 0 0 36px 14px #5388a0;
                transition: opacity 250ms;
            }

            &.selected:after {
                opacity: 1;
            }
        }
    }
}

@keyframes mainMenuItemHighlight {
    from {
        box-shadow: 0 0 36px 14px #5388a0;
    }
    to {
        box-shadow: 0 0 36px 14px #a09253;
    }
}

#wrapper {
    flex: 1 0 auto;
    margin: 0 auto 60px;
    padding-top: 332px;
    width: 400px;

    @media (max-width: 480px) {
        padding-left: 10vw;
        padding-right: 10vw;
        width: 100%;
    }
}

.section .header {
    color: #908174;
    font-size: 22px;
    font-weight: 900;
    letter-spacing: -.9px;
    text-align: center;
    text-transform: uppercase;
}

.section {
    display: none;

    .header:after {
        display: block;
        margin: 22px 0 18px 0;
        height: 26px;
        content: '';
        background: transparent url('../images/timeline-time-separator.png') no-repeat top center;
    }

    .article {
        .title {
            margin-bottom: 15px;
            color: #b8b8b8;
            font-family: 'Titillium Text Web', sans-serif;
            font-size: 34px;
            font-weight: 100;
            letter-spacing: 0;
            text-align: center;
            text-transform: uppercase;
        }

        .content {
            line-height: 18px;
            text-align: justify;

            a {
                color: #627a85;
                text-decoration: none;
                border-bottom: 1px dotted #46575f;
                transition: all 200ms;

                &:hover,
                &:focus{
                    color: #6b8591;
                    border: none;
                }
            }

            p {
                margin: 18px 0;
            }

            em {
                font-style: italic;
            }

            blockquote {
                margin-left: 12px;
                padding-left: 8px;
                color: #656159;
                font-style: italic;

                &:before {
                    display: block;
                    position: absolute;
                    margin-top: 7px;
                    margin-left: -22px;
                    height: 0;
                    content: '“';
                    font-size: 42px;
                }

                footer {
                    font-size: 90%;
                }
            }

            > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.home {
        .news {
            .article {
                .headline {
                    width: 100%;

                    /*display: flex;
                    flex-flow: column wrap;

                    .title {
                        order: 2;
                    }

                    .pubdate {
                        display: block;
                        margin-bottom: 16px;
                        color: #908174;
                        font-size: 22px;
                        font-weight: 900;
                        letter-spacing: -.9px;
                        text-align: center;
                        text-transform: uppercase;
                        order: 1;
                    }*/
                }
            }
        }
    }

    &.music {
        .files {
            margin-top: 20px;
            text-align: center;

            .file {
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    border-bottom-color: #354147;

                    .file-info {
                        color: #6f6f6f;
                        font-size: 13px;
                        white-space: pre;
                    }
                }
            }
        }
    } // end .music

    &.contact {
        form {
            label {
                display: none;
            }

            input:not([type='submit']),
            textarea {
                display: block;
                margin-bottom: 13px;
                padding: 7px 36px 5px 14px;
                width: 100%;
                color: #999;
                resize: none;
                background-color: rgba(60, 60, 60, .7);
                border: none;
                border-radius: 4px;
                transition: background-color 200ms;

                &:focus {
                    color: rgba(255, 255, 255, .4);
                    outline: none;
                    background-color: rgba(83, 136, 160, .3);
                }

                &.invalid {
                    background: rgba(160, 92, 83, .3) url('../images/form-input-status-error.png') no-repeat 97% 8px;
                }

                &:-moz-ui-invalid {
                    box-shadow: none;
                }
            }

            &.validating {
                input:not([type='submit']),
                textarea {
                    &:invalid {
                        background: rgba(160, 92, 83, .3) url('../images/form-input-status-error.png') no-repeat 97% 8px;
                    }
                }
            }

            textarea {
                margin-bottom: 20px;
                height: 150px;
            }

            .input-error {
                margin-bottom: 13px;
                color: #a05c53;
                font-style: italic;
            }

            input[type='submit'] {
                position: relative;
                z-index: 110;
                margin-bottom: 20px;
                padding: 8px 11px 5px 11px;
                color: #c7c7c7;
                font-size: 15px;
                font-weight: 600;
                text-shadow: 0 1px 0 #000;
                text-transform: uppercase;
                background: #131313 no-repeat 92% center;
                border: none;
                border-radius: 4px;
                transition: all 200ms;

                &:hover {
                    background: rgba(83, 136, 160, .3) url('../images/form-submit-status-success.png') no-repeat 92% center;
                }
                &:hover,
                &.active {
                    padding-right: 40px;
                }

                &.active {
                    background-color: #131313;
                }

                &.active.busy {
                    background-image: url('../images/form-submit-status-busy.gif')
                }

                &,
                &:hover,
                &:active,
                &:focus {
                    outline: none;
                }
            }

            .response-message {
                padding: 12px;
                color: #fff;
                text-align: center;
                border-radius: 4px;

                &.error {
                    background: #a05c53;
                }

                &.success {
                    background: #67a05f;
                }
            }
        }
    } // end .contact

    &.error {
        display: block;
        /*padding-top: 332px;*/

        .content {
            text-align: center;
        }
    } // end .error
}

#footer {
    flex-shrink: 0;
    height: 80px;
    line-height: 80px;
    font-size: 14px;
    text-align: center;
    background-color: #0e0e11;
    box-shadow: -1px 0 0 1px #2b2b31;

    .copyright-symbol {
        font-size: 13px;
    }

    .separator {
        margin: 0 5px;
    }

    a {
        display: inline-block;
        width: 43px;
        height: 48px;
        text-indent: -999999px;
        font-size: 0;
        line-height: 0;
        vertical-align: middle;
        opacity: .2;
        background: transparent url('../images/ndm-footer.png') no-repeat top left;
        background-size: 43px 48px;
        transition: all 200ms;

        &:hover,
        &:focus {
            opacity: 1;
            outline: none;
        }
    }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and (min--moz-device-pixel-ratio: 2),
only screen and (-o-min-device-pixel-ratio: 2/1),
only screen and (min-device-pixel-ratio: 2),
only screen and (min-resolution: 192dpi),
only screen and (min-resolution: 2dppx) {
    body {
        background-image: url('../images/background-logo-top@2x.jpg');
    }
    #footer {
        a {
            background-image: url('../images/ndm-footer@2x.png');
        }
    }
}

@media
only screen and (-webkit-min-device-pixel-ratio: 3),
only screen and (min--moz-device-pixel-ratio: 3),
only screen and (-o-min-device-pixel-ratio: 3/1),
only screen and (min-device-pixel-ratio: 3),
only screen and (min-resolution: 384dpi),
only screen and (min-resolution: 3dppx) {
    body {
        background-image: url('../images/background-logo-top@3x.jpg');
    }
    #footer {
        a {
            background-image: url('../images/ndm-footer@3x.png');
        }
    }
}