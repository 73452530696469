/* @font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-ExtraLight-webfont.eot');
	src: url('../fonts/SourceSansPro-ExtraLight-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-ExtraLight-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-ExtraLight-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-ExtraLight-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-ExtraLightIt-webfont.eot');
	src: url('../fonts/SourceSansPro-ExtraLightIt-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-ExtraLightIt-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-ExtraLightIt-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-ExtraLightIt-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-Light-webfont.eot');
	src: url('../fonts/SourceSansPro-Light-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-Light-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-Light-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-Light-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-LightIt-webfont.eot');
	src: url('../fonts/SourceSansPro-LightIt-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-LightIt-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-LightIt-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-LightIt-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 300;
	font-style: italic;
}
*/
@font-face {
  font-family: 'Source Sans Pro Web';
  src: url('../fonts/SourceSansPro-Regular-webfont.eot');
  src: url('../fonts/SourceSansPro-Regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Regular-webfont.woff') format('woff'), url('../fonts/SourceSansPro-Regular-webfont.ttf') format('truetype'), url('../fonts/SourceSansPro-Regular-webfont.svg#Source Sans Pro') format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro Web';
  src: url('../fonts/SourceSansPro-It-webfont.eot');
  src: url('../fonts/SourceSansPro-It-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-It-webfont.woff') format('woff'), url('../fonts/SourceSansPro-It-webfont.ttf') format('truetype'), url('../fonts/SourceSansPro-It-webfont.svg#Source Sans Pro') format('svg');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro Web';
  src: url('../fonts/SourceSansPro-Semibold-webfont.eot');
  src: url('../fonts/SourceSansPro-Semibold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Semibold-webfont.woff') format('woff'), url('../fonts/SourceSansPro-Semibold-webfont.ttf') format('truetype'), url('../fonts/SourceSansPro-Semibold-webfont.svg#Source Sans Pro') format('svg');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro Web';
  src: url('../fonts/SourceSansPro-SemiboldIt-webfont.eot');
  src: url('../fonts/SourceSansPro-SemiboldIt-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-SemiboldIt-webfont.woff') format('woff'), url('../fonts/SourceSansPro-SemiboldIt-webfont.ttf') format('truetype'), url('../fonts/SourceSansPro-SemiboldIt-webfont.svg#Source Sans Pro') format('svg');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro Web';
  src: url('../fonts/SourceSansPro-Bold-webfont.eot');
  src: url('../fonts/SourceSansPro-Bold-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Bold-webfont.woff') format('woff'), url('../fonts/SourceSansPro-Bold-webfont.ttf') format('truetype'), url('../fonts/SourceSansPro-Bold-webfont.svg#Source Sans Pro') format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Source Sans Pro Web';
  src: url('../fonts/SourceSansPro-BoldIt-webfont.eot');
  src: url('../fonts/SourceSansPro-BoldIt-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-BoldIt-webfont.woff') format('woff'), url('../fonts/SourceSansPro-BoldIt-webfont.ttf') format('truetype'), url('../fonts/SourceSansPro-BoldIt-webfont.svg#Source Sans Pro') format('svg');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Source Sans Pro Web';
  src: url('../fonts/SourceSansPro-Black-webfont.eot');
  src: url('../fonts/SourceSansPro-Black-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/SourceSansPro-Black-webfont.woff') format('woff'), url('../fonts/SourceSansPro-Black-webfont.ttf') format('truetype'), url('../fonts/SourceSansPro-Black-webfont.svg#Source Sans Pro') format('svg');
  font-weight: 900;
  font-style: normal;
}
/*
@font-face {
	font-family: 'Source Sans Pro Web';
	src: url('../fonts/SourceSansPro-BlackIt-webfont.eot');
	src: url('../fonts/SourceSansPro-BlackIt-webfont.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/SourceSansPro-BlackIt-webfont.woff') format('woff'),
		 url('../fonts/SourceSansPro-BlackIt-webfont.ttf') format('truetype'),
		 url('../fonts/SourceSansPro-BlackIt-webfont.svg#Source Sans Pro') format('svg');
	font-weight: 900;
	font-style: italic;
}
*/
@font-face {
  font-family: 'Titillium Text Web';
  src: url('../fonts/TitilliumText25L005-webfont.eot');
  src: url('../fonts/TitilliumText25L005-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/TitilliumText25L005-webfont.woff') format('woff'), url('../fonts/TitilliumText25L005-webfont.ttf') format('truetype'), url('../fonts/TitilliumText25L005-webfont.svg#Titillium Text') format('svg');
  font-weight: 100;
  font-style: normal;
}
/*
@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L004-webfont.eot');
    src: url('../fonts/TitilliumText25L004-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L004-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L004-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L004-webfont.svg#Titillium Text') format('svg');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L003-webfont.eot');
    src: url('../fonts/TitilliumText25L003-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L003-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L003-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L003-webfont.svg#Titillium Text') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L002-webfont.eot');
    src: url('../fonts/TitilliumText25L002-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L002-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L002-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L002-webfont.svg#Titillium Text') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L001-webfont.eot');
    src: url('../fonts/TitilliumText25L001-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L001-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L001-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L001-webfont.svg#Titillium Text') format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Titillium Text Web';
    src: url('../fonts/TitilliumText25L-webfont.eot');
    src: url('../fonts/TitilliumText25L-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/TitilliumText25L-webfont.woff') format('woff'),
         url('../fonts/TitilliumText25L-webfont.ttf') format('truetype'),
         url('../fonts/TitilliumText25L-webfont.svg#Titillium Text') format('svg');
    font-weight: 900;
    font-style: normal;
} */
/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
/**
* Prevent modern browsers from displaying `audio` without controls.
* Remove excess height in iOS 5 devices.
*/
audio:not([controls]) {
  display: none;
  height: 0;
}
/**
* Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
* Known issue: no IE 6 support.
*/
[hidden] {
  display: none;
}
/**
* 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
* `em` units.
* 2. Prevent iOS text size adjust after orientation change, without disabling
* user zoom.
*/
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}
/**
* Address `font-family` inconsistency between `textarea` and other form
* elements.
*/
html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}
/**
* Address `outline` inconsistency between Chrome and other browsers.
*/
a:focus {
  outline: thin dotted;
}
/**
* Improve readability when focused and also mouse hovered in all browsers.
*/
a:active,
a:hover {
  outline: 0;
}
/**
* Address styling not present in IE 7/8/9, Safari 5, and Chrome.
*/
abbr[title] {
  border-bottom: 1px dotted;
}
/**
* Address style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
*/
b,
strong {
  font-weight: bold;
}
/**
* Address styling not present in Safari 5 and Chrome.
*/
dfn {
  font-style: italic;
}
/**
* Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
*/
code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}
/**
* Improve readability of pre-formatted text in all browsers.
*/
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}
/**
* Address inconsistent and variable font size in all browsers.
*/
small {
  font-size: 80%;
}
/**
* Prevent `sub` and `sup` affecting `line-height` in all browsers.
*/
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
/**
* 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
* 2. Improve image quality when scaled in IE 7.
*/
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}
/**
* 1. Correct font size not being inherited in all browsers.
* 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
* and Chrome.
* 3. Improve appearance and consistency in all browsers.
*/
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}
/**
* Address Firefox 3+ setting `line-height` on `input` using `!important` in
* the UA stylesheet.
*/
button,
input {
  line-height: normal;
}
/**
* 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
* and `video` controls.
* 2. Correct inability to style clickable `input` types in iOS.
* 3. Improve usability and consistency of cursor style between image-type
* `input` and others.
* 4. Remove inner spacing in IE 7 without affecting normal text inputs.
* Known issue: inner spacing remains in IE 6.
*/
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}
/**
* Re-set default cursor for disabled elements.
*/
button[disabled],
html input[disabled] {
  cursor: default;
}
/**
* 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
* 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
* (include `-moz` to future-proof).
*/
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}
/**
* Remove inner padding and search cancel button in Safari 5 and Chrome
* on OS X.
*/
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
* Remove inner padding and border in Firefox 3+.
*/
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/**
* 1. Remove default vertical scrollbar in IE 6/7/8/9.
* 2. Improve readability and alignment in all browsers.
*/
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}
/*
* Clearfix: contain floats
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
* `contenteditable` attribute is included anywhere else in the document.
* Otherwise it causes space to appear at the top and bottom of elements
* that receive the `clearfix` class.
* 2. The use of `table` rather than `block` is only necessary if using
* `:before` to contain the top-margins of child elements.
*/
.clearfix:before,
.clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}
.clearfix:after {
  clear: both;
}
/*
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/
.clearfix {
  *zoom: 1;
}
::-moz-selection {
  color: #f1fbff;
  text-shadow: none;
  background: #627a85;
}
::selection {
  color: #f1fbff;
  text-shadow: none;
  background: rgba(98, 122, 133, 0.99);
  /* for some reason webkit tries to force a semi-transparent background, using an alpha value of 0.99 fixes that */
}
::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-style: italic;
}
:-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: italic;
}
::-moz-placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: italic;
}
:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  font-style: italic;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  -border-radius: 8px;
}
::-webkit-scrollbar-track {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  background-color: #999;
}
::-webkit-scrollbar-corner {
  display: none;
}
* {
  box-sizing: border-box;
}
html,
button,
input,
select,
textarea {
  font-family: 'Source Sans Pro Web', sans-serif;
  font-size: 14px;
  letter-spacing: -0.6px;
}
html {
  height: 100%;
  font-size: 15px;
  overflow-y: scroll;
}
html,
body {
  min-height: 100%;
  background-color: #25252d;
}
body {
  display: flex;
  flex-direction: column;
  cursor: default;
  color: #6f6f6f;
  background: #25252d url('../images/background-logo-top.jpg') no-repeat top center;
  background-size: 1200px 1080px;
}
.flexboxtweener,
.flexboxtweener > body {
  height: 100%;
}
html.error body {
  background-image: url('../images/background-logo-top-error.jpg');
}
#no-js-message,
#outdated-message {
  background-color: #bb5f5f;
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  padding: 1.5em 0;
  position: fixed;
  top: 0;
  text-align: center;
  width: 100%;
  z-index: 9999;
}
#no-js-message a,
#outdated-message a {
  color: #ffe203;
}
#outdated-message,
#outdated-message .ie {
  display: none;
}
.modal {
  position: fixed;
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .5;
  background-color: #25252d;
}
h1 {
  display: none;
}
#main-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin-bottom: 298px;
  height: 34px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
  text-transform: uppercase;
  background: transparent url('../images/main-menu-background.png') no-repeat top center;
}
#main-menu .list {
  height: 33px;
  overflow: hidden;
}
#main-menu .list .item {
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-left: 28px;
  line-height: 38px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}
#main-menu .list .item:first-child {
  margin: 0;
}
#main-menu .list .item a {
  color: #747474;
  text-decoration: none;
  transition: color 200ms;
}
#main-menu .list .item a:hover,
#main-menu .list .item.selected a {
  color: #d0d0d0;
}
#main-menu .list .item a:focus {
  outline: none;
}
#main-menu .list .item:after {
  display: block;
  position: absolute;
  z-index: -1;
  left: 50%;
  bottom: 0;
  margin-left: -20px;
  width: 40px;
  height: 4px;
  content: '';
  opacity: 0;
  border-radius: 10px;
  box-shadow: 0 0 36px 14px #5388a0;
  transition: opacity 250ms;
}
#main-menu .list .item.selected:after {
  opacity: 1;
}
@keyframes mainMenuItemHighlight {
  from {
    box-shadow: 0 0 36px 14px #5388a0;
  }
  to {
    box-shadow: 0 0 36px 14px #a09253;
  }
}
#wrapper {
  flex: 1 0 auto;
  margin: 0 auto 60px;
  padding-top: 332px;
  width: 400px;
}
@media (max-width: 480px) {
  #wrapper {
    padding-left: 10vw;
    padding-right: 10vw;
    width: 100%;
  }
}
.section .header {
  color: #908174;
  font-size: 22px;
  font-weight: 900;
  letter-spacing: -0.9px;
  text-align: center;
  text-transform: uppercase;
}
.section {
  display: none;
}
.section .header:after {
  display: block;
  margin: 22px 0 18px 0;
  height: 26px;
  content: '';
  background: transparent url('../images/timeline-time-separator.png') no-repeat top center;
}
.section .article .title {
  margin-bottom: 15px;
  color: #b8b8b8;
  font-family: 'Titillium Text Web', sans-serif;
  font-size: 34px;
  font-weight: 100;
  letter-spacing: 0;
  text-align: center;
  text-transform: uppercase;
}
.section .article .content {
  line-height: 18px;
  text-align: justify;
}
.section .article .content a {
  color: #627a85;
  text-decoration: none;
  border-bottom: 1px dotted #46575f;
  transition: all 200ms;
}
.section .article .content a:hover,
.section .article .content a:focus {
  color: #6b8591;
  border: none;
}
.section .article .content p {
  margin: 18px 0;
}
.section .article .content em {
  font-style: italic;
}
.section .article .content blockquote {
  margin-left: 12px;
  padding-left: 8px;
  color: #656159;
  font-style: italic;
}
.section .article .content blockquote:before {
  display: block;
  position: absolute;
  margin-top: 7px;
  margin-left: -22px;
  height: 0;
  content: '“';
  font-size: 42px;
}
.section .article .content blockquote footer {
  font-size: 90%;
}
.section .article .content > *:last-child {
  margin-bottom: 0;
}
.section.home .news .article .headline {
  width: 100%;
  /*display: flex;
                    flex-flow: column wrap;

                    .title {
                        order: 2;
                    }

                    .pubdate {
                        display: block;
                        margin-bottom: 16px;
                        color: #908174;
                        font-size: 22px;
                        font-weight: 900;
                        letter-spacing: -.9px;
                        text-align: center;
                        text-transform: uppercase;
                        order: 1;
                    }*/
}
.section.music .files {
  margin-top: 20px;
  text-align: center;
}
.section.music .files .file {
  margin-bottom: 12px;
}
.section.music .files .file:last-child {
  margin-bottom: 0;
}
.section.music .files .file a {
  border-bottom-color: #354147;
}
.section.music .files .file a .file-info {
  color: #6f6f6f;
  font-size: 13px;
  white-space: pre;
}
.section.contact form label {
  display: none;
}
.section.contact form input:not([type='submit']),
.section.contact form textarea {
  display: block;
  margin-bottom: 13px;
  padding: 7px 36px 5px 14px;
  width: 100%;
  color: #999;
  resize: none;
  background-color: rgba(60, 60, 60, 0.7);
  border: none;
  border-radius: 4px;
  transition: background-color 200ms;
}
.section.contact form input:not([type='submit']):focus,
.section.contact form textarea:focus {
  color: rgba(255, 255, 255, 0.4);
  outline: none;
  background-color: rgba(83, 136, 160, 0.3);
}
.section.contact form input:not([type='submit']).invalid,
.section.contact form textarea.invalid {
  background: rgba(160, 92, 83, 0.3) url('../images/form-input-status-error.png') no-repeat 97% 8px;
}
.section.contact form input:not([type='submit']):-moz-ui-invalid,
.section.contact form textarea:-moz-ui-invalid {
  box-shadow: none;
}
.section.contact form.validating input:not([type='submit']):invalid,
.section.contact form.validating textarea:invalid {
  background: rgba(160, 92, 83, 0.3) url('../images/form-input-status-error.png') no-repeat 97% 8px;
}
.section.contact form textarea {
  margin-bottom: 20px;
  height: 150px;
}
.section.contact form .input-error {
  margin-bottom: 13px;
  color: #a05c53;
  font-style: italic;
}
.section.contact form input[type='submit'] {
  position: relative;
  z-index: 110;
  margin-bottom: 20px;
  padding: 8px 11px 5px 11px;
  color: #c7c7c7;
  font-size: 15px;
  font-weight: 600;
  text-shadow: 0 1px 0 #000;
  text-transform: uppercase;
  background: #131313 no-repeat 92% center;
  border: none;
  border-radius: 4px;
  transition: all 200ms;
}
.section.contact form input[type='submit']:hover {
  background: rgba(83, 136, 160, 0.3) url('../images/form-submit-status-success.png') no-repeat 92% center;
}
.section.contact form input[type='submit']:hover,
.section.contact form input[type='submit'].active {
  padding-right: 40px;
}
.section.contact form input[type='submit'].active {
  background-color: #131313;
}
.section.contact form input[type='submit'].active.busy {
  background-image: url('../images/form-submit-status-busy.gif');
}
.section.contact form input[type='submit'],
.section.contact form input[type='submit']:hover,
.section.contact form input[type='submit']:active,
.section.contact form input[type='submit']:focus {
  outline: none;
}
.section.contact form .response-message {
  padding: 12px;
  color: #fff;
  text-align: center;
  border-radius: 4px;
}
.section.contact form .response-message.error {
  background: #a05c53;
}
.section.contact form .response-message.success {
  background: #67a05f;
}
.section.error {
  display: block;
  /*padding-top: 332px;*/
}
.section.error .content {
  text-align: center;
}
#footer {
  flex-shrink: 0;
  height: 80px;
  line-height: 80px;
  font-size: 14px;
  text-align: center;
  background-color: #0e0e11;
  box-shadow: -1px 0 0 1px #2b2b31;
}
#footer .copyright-symbol {
  font-size: 13px;
}
#footer .separator {
  margin: 0 5px;
}
#footer a {
  display: inline-block;
  width: 43px;
  height: 48px;
  text-indent: -999999px;
  font-size: 0;
  line-height: 0;
  vertical-align: middle;
  opacity: .2;
  background: transparent url('../images/ndm-footer.png') no-repeat top left;
  background-size: 43px 48px;
  transition: all 200ms;
}
#footer a:hover,
#footer a:focus {
  opacity: 1;
  outline: none;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  body {
    background-image: url('../images/background-logo-top@2x.jpg');
  }
  #footer a {
    background-image: url('../images/ndm-footer@2x.png');
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 3), only screen and (min--moz-device-pixel-ratio: 3), only screen and (-o-min-device-pixel-ratio: 3/1), only screen and (min-device-pixel-ratio: 3), only screen and (min-resolution: 384dpi), only screen and (min-resolution: 3dppx) {
  body {
    background-image: url('../images/background-logo-top@3x.jpg');
  }
  #footer a {
    background-image: url('../images/ndm-footer@3x.png');
  }
}
